.breadcrumb .blockie {
  height: 16px;
  border: 0;
  padding: 0;
}

.breadcrumb .addressContainer {
  height: fit-content;
}

.header {
  margin-bottom: var(--spacing-4);
}

.header h1 {
  font-size: 24px;
  line-height: normal;
  text-align: left;
  margin-bottom: 10px;
  display: flex;
  gap: 10px;
}

.header .modBlockie {
  height: 24px;
  border: 0;
  padding: 0;
}

.header .modAddress {
  font-size: 20px;
}
