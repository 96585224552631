.button {
  font-family: inherit;
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  padding: var(--spacing-3);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.button:hover,
.button.open:hover {
  background: var(--bg-color-hover);
}

.button.open {
  background: rgb(217 212 173 / 10%);
}

.button.open .buttonIcon {
  transform: rotate(180deg);
}
