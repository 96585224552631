.tabGroup {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: var(--spacing-3);
  border-bottom: 2px solid var(--border-color);
}

.tabGroup button {
  background: none;
  outline: none;
  border: none;
  color: white;
  font-family: var(--font-body);
  font-size: 14px;
  cursor: pointer;
  padding: 10px 20px;
  position: relative;
}

.tabGroup button:hover {
  background: var(--bg-color);
}

.tabGroup button.selected:after {
  content: "";
  height: 2px;
  width: 100%;
  bottom: -2px;
  left: 0;
  background: white;
  position: absolute;
}
