.loading {
  cursor: progress;
}

.loading ul {
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  list-style-type: none;
  margin-bottom: 20px;
  border-bottom: 2px solid var(--border-color);
  opacity: 0.5;
}

.loading li {
  background: none;
  outline: none;
  border: none;
  color: white;
  font-family: var(--font-body);
  font-size: 14px;
  cursor: progress;
  padding: 10px 20px;
  position: relative;
}

.loading .placeholder {
  height: 150px;
  width: 100%;
  background: linear-gradient(
    -45deg,
    var(--bg-color) 40%,
    var(--bg-color-hover) 50%,
    var(--bg-color) 60%
  );
  background-size: 300%;
  background-position-x: 100%;
  animation: shimmer 1s infinite linear;
}

@keyframes shimmer {
  to {
    background-position-x: 0%;
  }
}

.permissionsWrapper {
  position: relative;
}

.toolbar {
  position: absolute;
  top: -52px;
  right: var(--spacing-3);
  font-size: 12px;
}
