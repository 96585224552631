.permissionsWrapper {
  position: relative;
}

.toolbar {
  position: absolute;
  top: -52px;
  right: var(--spacing-3);
  font-size: 12px;
}
