.header {
  background: var(--bg-color);
  padding: var(--spacing-3);
}

.header h2 {
  font-size: 20px;
  margin: 0;
}

.roleFilter {
  flex-grow: 1;
  max-width: 400px;
  background-color: var(--bg-color);
  position: relative;
}

.roleFilter .magnifyingGlass {
  height: 20px;
  stroke: var(--border-color);
  position: absolute;
  left: 12px;
  top: 12px;
}

.roleFilter input {
  width: 100%;
  height: auto;
  background: none;
  border: none;
  color: var(--text-color);
  font-size: 12px;
  padding: 10px 10px;
  padding-left: 50px;
  border: 1px solid var(--border-color);
  font-family: monospace;
  box-sizing: border-box;
}

.roleFilter input:focus {
  border: 1px solid white;
  filter: brightness(1.5);
}

.roleFilter input:hover {
  filter: brightness(1.5);
}

.row {
  text-decoration: none;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--spacing-3);
  cursor: pointer;
  background: var(--bg-color);
  padding: var(--spacing-3);
}

.row:hover {
  background: var(--bg-color-hover);
  color: white;
}

.parsedKey {
  font-size: 20px;
}

.key code {
  width: 100px;
  height: fit-content;
  overflow: hidden;
  text-overflow: ellipsis;
  opacity: 0.7;
  font-family: var(--font-mono);
  font-size: 10px;
  padding: 2px 5px;
}

.meta {
  display: flex;
  align-items: center;
}

.empty {
  text-align: center;
  padding: var(--spacing-3) 0;
}

.roleName {
  width: 30%;
  min-width: 250px;
}
