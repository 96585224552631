.pass {
  opacity: 0.7;
}
.pass .pass {
  opacity: 1;
}

.logicalCondition {
  border: 1px dashed var(--border-color);
  min-height: 40px;
}

.logicalChildren {
  padding: var(--spacing-2);
}

.logicalConditionBar {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: var(--condition-indent);
  background-color: var(--bg-color);
}

.logicalBranchSeparator {
  border-top: 1px dashed var(--border-color);
  margin: var(--spacing-1) 0;
}
.logicalBranchSeparator .operatorLabel {
  position: absolute;
  left: -24px;
  writing-mode: vertical-lr;
  transform: rotate(-180deg);
  text-transform: uppercase;
  text-align: center;
  height: 40px;
  margin-top: -20px;
  font-family: var(--font-mono), monospace;
  font-size: 12px;
  line-height: var(--condition-indent);
}
.singleItemOperatorLabel {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  writing-mode: vertical-lr;
  transform: rotate(-180deg);
  text-transform: uppercase;
  text-align: center;
  font-family: var(--font-mono), monospace;
  font-size: 12px;
  line-height: var(--condition-indent);
}

.conditionContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2);
}

.conditionBody {
  position: relative;
  margin-left: var(--condition-indent);
}

.conditionBody.topLevelCondition {
  margin-left: 0;
}

.conditionHeader {
  background: var(--bg-color);
  padding: var(--spacing-2);
}
.conditionHeader.hoverable {
  cursor: pointer;
}
.conditionHeader.hoverable:hover {
  background: var(--bg-color-hover);
}

.conditionHeaderInner {
  width: 100%;
}

.paramInfo {
  font-family: var(--font-mono), monospace;
  font-size: 12px;
}

.paramInfoLabel {
  min-width: 60px;
}

.operator {
  white-space: nowrap;
  font-family: var(--font-mono), monospace;
  font-size: 12px;
  font-style: italic;
}

.unsupported {
  padding: var(--spacing-2);
}

.unsupported pre {
  color: var(--text-color);
  margin: 0;
  background-color: var(--bg-color);
  padding: var(--spacing-2);
  border-radius: var(--border-radius);
  font-family: var(--font-mono), monospace;
  font-size: 12px;
  line-height: 1.5;
  overflow: auto;
  max-height: 200px;
}

.unsupported p {
  margin: 0;
  font-size: 12px;
  margin-bottom: var(--spacing-2);
}

.verticalGuide {
  position: absolute;
  top: -62px;
  bottom: 0;
  left: -17px;
  width: 1px;
  background-color: var(--border-color);
  z-index: 10;
}

.verticalGuide::after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 10px;
  height: 1px;
  background-color: var(--border-color);
}

.verticalGuide::after {
  top: auto;
  bottom: 0px;
}

.avatar {
  border-radius: 4px;
  border: 1px solid var(--border-color);
  padding: 0 4px 0 2px;
  font-style: normal;
}

.collapseIcon {
  margin-right: var(--spacing-1);
}
.openIcon {
  transform: rotate(180deg);
}

.conditionInput:read-only {
  background-color: rgba(0, 0, 0, 0.3);
  border: none;
  font-size: 12px;
  padding: 0 var(--spacing-1);
  height: auto;
  line-height: 16px;
}

.compValue {
  width: 100%;
}

.abiMismatch {
  color: var(--error-color);
  font-weight: bold;
}
