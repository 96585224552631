.main {
  display: flex;
  flex-direction: column;
  gap: calc(var(--spacing-5) * 2);
}

.main h3 {
  font-size: 20px;
}

.about h2 {
  font-size: 32px;
  margin: 0;
}

.about p {
  font-size: 16px;
  line-height: 1.8;
  max-width: 500px;
  background-color: var(--bg-color);
  padding: var(--spacing-3);
}

.learn {
  width: 50%;
  max-width: 500px;
}

.learnLink {
  width: 50%;
  text-decoration: none;
  background-color: var(--bg-color);
  padding: var(--spacing-3);
}

.learnLink:hover {
  background-color: var(--bg-color-hover);
  color: white;
}

.learnLink h4 {
  margin: 0;
}

.learnLink figure {
  margin: 0;
  font-size: 20px;
}

.learnLink p {
  font-family: var(--font-mono);
  font-size: 12px;
  opacity: 0.7;
}

.viewRole {
  width: 50%;
}

.attachButton {
  width: fit-content;
  padding: var(--spacing-2) var(--spacing-3);
}
