.flex {
  display: flex;
}
.row {
  flex-direction: row;
}
.column {
  flex-direction: column;
}

.gap0 {
  gap: 0;
}
.gap1 {
  gap: var(--spacing-1);
}
.gap2 {
  gap: var(--spacing-2);
}
.gap3 {
  gap: var(--spacing-3);
}
.gap4 {
  gap: var(--spacing-4);
}
.gap5 {
  gap: var(--spacing-5);
}

.wrap {
  flex-wrap: wrap;
}
