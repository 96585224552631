.switch {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: var(--spacing-2);
  background: none;
  border: none;

  font-family: var(--font-body);
  color: white;
  padding: 0;
  font-size: 16px;
}

.disabled {
  cursor: initial;
  pointer-events: none;
}

.disabled .knobCase {
  opacity: 0.7;
}

.knobCase {
  border: 1px solid var(--border-color);
  border-radius: 100px;
  height: 1em;
  aspect-ratio: 2;
  background: rgb(255 107 107 / 8%);
}

.knobCase.checkedKnobCase {
  background: rgb(0 214 0 / 5%);
}

.knob {
  height: 100%;
  aspect-ratio: 1;
  border-radius: 100px;
  background-color: var(--border-color);
  transition: transform 0.01s;

  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s ease-in-out;
  color: #ff6b6b;
  font-size: 0.8em;
}
.knob svg {
  position: relative;
  left: -0.5px;
  top: 0.5px;
}

.knob.checkedKnob {
  transform: translateX(100%);
  color: #00d600;
}
