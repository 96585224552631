.callTo {
  user-select: none;
}

.callTo > label {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.7);
  background-color: var(--bg-color);
  width: fit-content;
  padding: 0px 5px;
  display: block;
}

.callData {
  width: 66%;
  flex-grow: 3;
}

.comment {
  width: 33%;
  flex-grow: 1;
  font-size: 12px;
  padding-left: 1.3em;
  text-indent: -1.3em;
  user-select: none;
}

.toolbar {
  font-size: 12px;
  padding: var(--spacing-2) var(--spacing-4);
}
