.hidden {
  visibility: hidden;
}

/* .identical:not(.identical .identical) {
  opacity: 0.6;
} */

.added:not(.added .added) {
  background-color: #35774861;
  border: 1px solid #357748;
}

.removed:not(.removed .removed) {
  background-color: #88242461;
  border: 1px solid #882424;
}

.modified {
  background-color: #2b477e61;
  border: 1px solid #2b477e;
}

.placeholderContainer {
  overflow-x: hidden;
  position: relative;
}
.placeholderFlex {
  width: 200%;
}
.placeholderVisible,
.placeholderHidden {
  width: 100%;
}
.placeholderHidden {
  visibility: hidden;
}

.stretch {
  min-height: 100%;
}
