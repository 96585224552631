.main {
  padding: var(--spacing-1);
}

.hash {
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  font-family: var(--font-mono);
  line-height: 18.5px;
}

.header {
  margin-bottom: 24px;
}

.headerHash {
  display: flex;
  gap: var(--spacing-2);
  align-items: center;
  font-family: var(--font-mono);
}

.permissionsWrapper {
  position: relative;
}

.toolbar {
  position: absolute;
  top: -50px;
  right: var(--spacing-3);
  font-size: 12px;
}
