.page {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  width: 100vw;
  min-width: 640px;
  margin: 0 auto;
  /* overflow: auto; */
}

.body {
  display: flex;
  flex-direction: column;
}
.body.noScroll {
  overflow: hidden;
  height: 100%;
}

.topBar {
  flex-grow: 0;
  flex-shrink: 0;
  padding: 16px;
}

.main {
  padding: var(--spacing-4);
  box-sizing: border-box;
}
.main.noScroll {
  flex-grow: 1;
}

.homeBreadcrumb {
  flex-direction: row;
  align-items: center;
}

.breadcrumb label {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.7);
  background-color: var(--bg-color);
  width: fit-content;
  padding: 0px 5px;
}

.breadcrumb {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: var(--spacing-2);
  text-decoration: none;
  padding: 0;
  white-space: nowrap;
  background: none;
  border: none;
  padding: var(--spacing-2);
}

a.breadcrumb:hover {
  background-color: var(--bg-color);
  color: white;
}

.breadcrumb label {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.7);
  background-color: var(--bg-color);
  width: fit-content;
  padding: 0px 5px;
  margin-left: -2px;
}

.appLogo {
  display: flex;
  align-items: center;
  gap: 8px;
}

.appLogo img {
  margin-left: -1%;
}

.footer {
  border-top: 1px solid var(--border-color);
  margin: 0 var(--spacing-3);
  padding: var(--spacing-3) 0;
}

.footer a {
  opacity: 0.7;
  font-size: 12px;
  text-decoration: none;
}

.footer a:hover {
  opacity: 1;
}
