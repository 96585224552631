.container {
  height: 40px;
}

.blockies {
  height: 100%;
}

.address {
  font-family: var(--font-mono), monospace;
  font-size: 14px;
  flex-shrink: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}

.link {
  text-decoration: none;
}

.blockieContainer {
  height: 100%;
  width: auto;
  aspect-ratio: 1;
  flex-shrink: 0;
}
