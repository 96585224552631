.row {
  text-decoration: none;
  color: white;
  border-bottom: 1px solid rgba(217, 212, 173, 0.3);
  display: flex;
  justify-content: flex-start;
  align-items: normal;
}

.row:hover {
  background-color: rgba(217, 212, 173, 0.02);
  color: white;
}

.row > div {
  padding: var(--spacing-3);
}

.key {
  flex-grow: 3;
}

.members {
  flex-grow: 1;
}

.meta {
  display: flex;
  align-items: center;
}

.empty {
  padding: var(--spacing-3);
  text-align: center;
  opacity: 0.8;
}

.added {
  background-color: #35774861;
  border: 1px solid #357748;
}

.removed {
  background-color: #88242461;
  border: 1px solid #882424;
}
